import { ResourceName } from "@/types/models/resourceName";
import ProjectCategory from "@/types/models/projectCategory";
import ApiService from "../api.service";

const ProjectCategoryService = {
  async create(resourceData: ProjectCategory) {
    return ApiService.post("api/project-categories", resourceData);
  },
  async getData() {
    return ApiService.get("/api/project-categories");
  },
  async getDropDown(
    dependsOnResourceName?: ResourceName,
    dependsOnResourceId?: number
  ) {
    const params = { dependsOnResourceName, dependsOnResourceId };
    return ApiService.customRequest({
      url: "/api/dropdown/projectcategories",
      method: "get",
      params,
    });
  },
};

export default ProjectCategoryService;
