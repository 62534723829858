import ProjectCategoryService from "@/services/models/project_category.service";
import { ResourceName } from "./../types/models/resourceName";

export default function useDependencyField() {
  async function onChange(
    resourseName: ResourceName,
    dependsOnResourceName: ResourceName,
    dependsOnResourceId: number,
    data: any[]
  ) {
    let service;
    switch (resourseName) {
      case "ProjectCategory":
        service = ProjectCategoryService;
        break;
      default:
        service = ProjectCategoryService;
        break;
    }
    const promise = await service.getDropDown(
      dependsOnResourceName,
      dependsOnResourceId
    );

    data.splice(0, data.length, ...promise.data);
  }

  return {
    onChange,
  };
}
