import Employee from "@/types/models/employee";
import ApiService from "../api.service";

const EmployeeService = {
  async create(resourceData: Employee) {
    return ApiService.post("/api/employees", resourceData);
  },
  async getData() {
    return ApiService.get("/api/employees");
  },
  async getDropDown() {
    return ApiService.get("/api/dropdown/employees");
  },
  async getEnum(colName: string) {
    return ApiService.get("api/enum/employees/" + colName);
  },
  async getSingleData(id: number | string) {
    return ApiService.get("api/employees/" + id);
  },
  async delete(id: number) {
    return ApiService.delete("api/employees/" + id);
  },
  async resourceRelationship(id: number | string) {
    return ApiService.get("api/employee/relationships/" + id);
  },
  async getLoggedInEmployee() {
    return ApiService.get("api/employee/get-logged-in-employee");
  },
};

export default EmployeeService;
