import Todo from "@/types/models/todo";
import ApiService from "../api.service";

const TodoService = {
  async create(resourceData: Todo) {
    return ApiService.post("api/todos", resourceData);
  },
  async getData(type = "active") {
    return ApiService.get("/api/todos", { type });
  },
  async getEnum(colName: string) {
    return ApiService.get("api/enum/todos/" + colName);
  },
  async delete(id: number) {
    return ApiService.delete("api/todos/" + id);
  },
};

export default TodoService;
