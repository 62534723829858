import ApiService from "@/services/api.service";

const PlanningService = {
  async fetchEvents(start: string, end: string, modelType: string) {
    const params = { start, end, modelType };
    return ApiService.customRequest({
      url: "/api/planning/events",
      method: "get",
      params,
    });
  },

  async fetchResources(modelType: string) {
    const params = { modelType };
    return ApiService.customRequest({
      url: "/api/planning/resources",
      method: "get",
      params,
    });
  },

  async updateEvent(
    data: {
      start: string | Date;
      end: string | Date;
      employee_id?: number;
    },
    id: number,
    resourceName: string
  ) {
    ApiService.post("api/planning/" + id + "/update", {
      data: data,
      resourceName: resourceName,
    });
  },
};

export default PlanningService;
