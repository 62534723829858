import { ResourceName } from "./../../types/models/resourceName";
import Document from "@/types/models/document";
import ApiService from "../api.service";

const DocumentService = {
  async create(
    resourceData: Document,
    viaResource: ResourceName,
    viaResourceId: number
  ) {
    const formData = new FormData();
    formData.append("resourceName", viaResource);
    formData.append("resourceId", viaResourceId.toString());
    formData.append("file", resourceData.file);
    formData.append("comment", resourceData.comment ?? "");
    return ApiService.customRequest({
      url: "/api/documents",
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async getData() {
    return ApiService.get("api/documents");
  },
  async delete(id: number) {
    return ApiService.delete("api/documents/" + id);
  },
  async getSingleData(id: number) {
    return ApiService.get("api/documents/" + id);
  },
};

export default DocumentService;
