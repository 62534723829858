import Permission from "@/types/models/permission";
import EmployeeService from "@/services/models/employee.service";
import Employee from "@/types/models/employee";
import Project from "@/types/models/project";
import ProjectCategory from "@/types/models/projectCategory";
import Task from "@/types/models/task";
import Todo from "@/types/models/todo";
import TodoCategory from "@/types/models/todoCategory";
import User from "@/types/models/user";
import Document from "@/types/models/document";
import ProjectService from "./models/project.service";
import TaskService from "./models/task.service";
import TodoService from "./models/todo.service";
import TodoCategoryService from "./models/todo_category.service";
import WorkingHourService from "./models/working_hour.service";
import ProjectCategoryService from "./models/project_category.service";
import WorkingHour from "@/types/models/workingHour";
import { ResourceName } from "@/types/models/resourceName";
import PermissionService from "./models/permission.service";
import Role from "@/types/models/role";
import RoleService from "./models/role.service";
import UserService from "./models/user.service";
import DocumentService from "./models/document.service";
import GroupService from "./models/group.service";
import Group from "@/types/models/group";

const ModelService = {
  async create<T extends ResourceName>(
    resource: T,
    resourceData: {
      Project: Project;
      Task: Task;
      Todo: Todo;
      ProjectCategory: ProjectCategory;
      WorkingHour: WorkingHour;
      Employee: Employee;
      Permission: Permission;
      Role: Role;
      User: User;
      Document: Document;
      TodoCategory: TodoCategory;
      Group: Group;
    }[T],
    viaResource: ResourceName | null = null,
    viaResourceId: number | null = null
  ) {
    switch (resource) {
      case "Project":
        return ProjectService.create(resourceData as Project);
      case "Task":
        return TaskService.create(resourceData as Task);
      case "Todo":
        return TodoService.create(resourceData as Todo);
      case "ProjectCategory":
        return ProjectCategoryService.create(resourceData as ProjectCategory);
      case "WorkingHour":
        return WorkingHourService.create(resourceData as WorkingHour);
      case "Employee":
        return EmployeeService.create(resourceData as Employee);
      case "Permission":
        return PermissionService.create(resourceData as Permission);
      case "Role":
        return RoleService.create(resourceData as Role);
      case "User":
        return UserService.create(resourceData as User);
      case "Document":
        return DocumentService.create(
          resourceData as Document,
          viaResource as ResourceName,
          viaResourceId as number
        );
      case "TodoCategory":
        return TodoCategoryService.create(resourceData as TodoCategory);
      case "Group":
        return GroupService.create(resourceData as Group);
    }
  },

  async getSingleResouceData<T extends ResourceName>(
    resource: T,
    resourceId: number
  ) {
    let promise;
    switch (resource) {
      case "Task":
        promise = await (
          await TaskService.getSingleTaskData(resourceId)
        ).data.task;
        break;
      case "WorkingHour":
        promise = await (
          await WorkingHourService.getSingleWorkingHourData(resourceId)
        ).data.workingHour;
        break;
      case "Employee":
        promise = await (
          await EmployeeService.getSingleData(resourceId)
        ).data.employee;
        break;
      case "Group":
        promise = await (
          await GroupService.getSingleData(resourceId)
        ).data.group;
        break;
      case "Role":
        promise = await (await RoleService.getSingleData(resourceId)).data.role;
        break;
      case "User":
        promise = await (await UserService.getSingleData(resourceId)).data.user;
        break;
      case "Document":
        promise = await (
          await DocumentService.getSingleData(resourceId)
        ).data.document;
        break;
    }
    return promise;
  },

  async deleteResource<T extends ResourceName>(
    resource: T,
    resourceId: number
  ) {
    switch (resource) {
      case "WorkingHour":
        WorkingHourService.delete(resourceId);
        break;
      case "Employee":
        EmployeeService.delete(resourceId);
        break;
      case "Task":
        TaskService.delete(resourceId);
        break;
      case "Role":
        RoleService.delete(resourceId);
        break;
      case "User":
        UserService.delete(resourceId);
        break;
      case "Document":
        DocumentService.delete(resourceId);
        break;
      case "Group":
        GroupService.delete(resourceId);
        break;
      case "Todo":
        TodoService.delete(resourceId);
    }
  },

  async getResourceRelationships<T extends ResourceName>(
    resource: T,
    resourceId: number
  ) {
    let promise;
    switch (resource) {
      case "WorkingHour":
        promise = await (
          await WorkingHourService.resourceRelationship(resourceId)
        ).data.relationships;
        break;
      case "Employee":
        promise = await (
          await EmployeeService.resourceRelationship(resourceId)
        ).data.relationships;
        break;
      case "Task":
        promise = await (
          await TaskService.resourceRelationship(resourceId)
        ).data.relationships;
        break;
      case "Document":
        promise = Promise.resolve([]);
        break;
      case "Role":
        promise = Promise.resolve([]);
        break;
      case "User":
        promise = await (
          await UserService.resourceRelationship(resourceId)
        ).data.relationships;
        break;
      case "Group":
        promise = await (
          await GroupService.resourceRelationship(resourceId)
        ).data.relationships;
        break;
      default:
        break;
    }
    return promise;
  },
};

export default ModelService;
