import {
  InlineMenuPosition,
  LayoutMode,
  MenuMode,
  MenuThemeName,
  ThemeName,
  TopbarThemeName,
} from "@/types/theme/theme_properties";
import { defineStore } from "pinia";
import { themes, menuThemes, topbarThemes } from "@/constants";
import ThemeService from "@/services/theme.service";
import { ThemeConfiguration } from "@/types/models/themeConfiguration";

const defaultThemeSetting: ThemeConfiguration = {
  layoutMode: "light" as LayoutMode,
  topbarTheme: "blue" as TopbarThemeName,
  menuTheme: "light" as MenuThemeName,
  menuMode: "static" as MenuMode,
  theme: "blue" as ThemeName,
  inlineMenuPosition: "bottom" as InlineMenuPosition,
  RTL: false,
  isNewThemeLoaded: false,
  language: "en",
};

export const useThemeStore = defineStore("ThemeStore", {
  state() {
    return {
      themeConfig: getSavedState("themeConfig") ?? defaultThemeSetting,
      themeCollections: {
        themes,
        menuThemes,
        topbarThemes,
      },
    };
  },
  actions: {
    saveSystemConfigurations() {
      ThemeService.saveThemeConfiguration(this.themeConfig)
        .then(() => {
          saveState("themeConfig", this.themeConfig);
        })
        .catch(async (error) => {
          saveState("themeConfig", null);
          console.log("error", error);
        });
    },
    fetchSystemConfiguration() {
      ThemeService.getUserThemeConfig()
        .then((response) => {
          if (Object.keys(response.data.userThemeConfig).length > 0) {
            this.themeConfig = Object.assign(
              {},
              JSON.parse(JSON.stringify(response.data.userThemeConfig))
            );
            saveState("themeConfig", this.themeConfig);
          } else {
            saveState("themeConfig", null);
          }
        })
        .catch(async (error) => {
          saveState("themeConfig", null);
          console.log("error", error);
        });
    },
  },
});

function getSavedState(key: string) {
  return JSON.parse(window.localStorage.getItem(key) as string);
}

function saveState(key: string, state: ThemeConfiguration | null) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
