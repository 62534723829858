import axios from "axios";

const ApiService = {
  init(baseURL: string | undefined, piniaStore: any) {
    axios.defaults.baseURL = baseURL;
    piniaStore.validate();
  },

  get(resource: string, params?: any) {
    return axios.get(resource, { params });
  },

  post(resource: string, data: any) {
    return axios.post(resource, data);
  },

  put(resource: string, data: any) {
    return axios.put(resource, data);
  },

  delete(resource: string) {
    return axios.delete(resource);
  },

  customRequest(data: any) {
    return axios(data);
  },
};

export default ApiService;
