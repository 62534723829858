import Role from "@/types/models/role";
import ApiService from "../api.service";

const RoleService = {
  async create(resourceData: Role) {
    ApiService.post("/api/roles", resourceData);
  },
  async getData() {
    return ApiService.get("/api/roles");
  },
  async getSingleData(id: number | string) {
    return ApiService.get("api/roles/" + id);
  },
  async delete(id: number) {
    return ApiService.delete("api/roles/" + id);
  },
  async update(role: Role) {
    return ApiService.put("api/roles/" + role.id, role);
  },
};

export default RoleService;
