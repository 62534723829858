import UserService from "@/services/models/user.service";

import { defineStore } from "pinia";

export const useUserPermissionStore = defineStore("UserPermissionStore", {
  state() {
    return {
      userPermissions: [] as string[],
    };
  },
  actions: {
    getUserPermissions() {
      UserService.getAllPermission()
        .then((response) => {
          this.userPermissions = response.data.permissions;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
});
