import Task from "@/types/models/task";
import ApiService from "../api.service";

const TaskService = {
  async create(resourceData: Task) {
    return ApiService.post("api/tasks", resourceData);
  },
  async getData() {
    return ApiService.get("api/tasks");
  },
  async getEnum(colName: string) {
    return ApiService.get("api/enum/tasks/" + colName);
  },
  async getSingleTaskData(id: number | string) {
    return ApiService.get("api/tasks/" + id);
  },
  async delete(id: number) {
    return ApiService.delete("api/tasks/" + id);
  },
  async resourceRelationship(id: number | string) {
    return ApiService.get("api/task/relationships/" + id);
  },
};

export default TaskService;
