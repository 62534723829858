export default function useGroupBy() {
  const groupBy = (data: any[], key: string) => {
    return data.reduce((grouped: any, current: any) => {
      (grouped[current[key]] = grouped[current[key]] || []).push(current);
      return grouped;
    }, {});
  };

  return {
    groupBy,
  };
}
