import { MenuItem } from "./types/theme/menu";
import { MenuTheme, Theme, TopbarTheme } from "./types/theme/theme_properties";

export const themes: Theme[] = [
  { name: "indigo", color: "#2f8ee5" },
  { name: "pink", color: "#E91E63" },
  { name: "purple", color: "#9C27B0" },
  { name: "deeppurple", color: "#673AB7" },
  { name: "blue", color: "#2196F3" },
  { name: "lightblue", color: "#03A9F4" },
  { name: "cyan", color: "#00BCD4" },
  { name: "teal", color: "#009688" },
  { name: "green", color: "#4CAF50" },
  { name: "lightgreen", color: "#8BC34A" },
  { name: "lime", color: "#CDDC39" },
  { name: "yellow", color: "#FFEB3B" },
  { name: "amber", color: "#FFC107" },
  { name: "orange", color: "#FF9800" },
  { name: "deeporange", color: "#FF5722" },
  { name: "brown", color: "#795548" },
  { name: "bluegrey", color: "#607D8B" },
];

export const menuThemes: MenuTheme[] = [
  { name: "light", color: "#FDFEFF" },
  { name: "dark", color: "#434B54" },
  { name: "indigo", color: "#1A237E" },
  { name: "bluegrey", color: "#37474F" },
  { name: "brown", color: "#4E342E" },
  { name: "cyan", color: "#006064" },
  { name: "green", color: "#2E7D32" },
  { name: "deeppurple", color: "#4527A0" },
  { name: "deeporange", color: "#BF360C" },
  { name: "pink", color: "#880E4F" },
  { name: "purple", color: "#6A1B9A" },
  { name: "teal", color: "#00695C" },
];

export const topbarThemes: TopbarTheme[] = [
  { name: "lightblue", color: "#2E88FF" },
  { name: "dark", color: "#363636" },
  { name: "white", color: "#FDFEFF" },
  { name: "blue", color: "#1565C0" },
  { name: "deeppurple", color: "#4527A0" },
  { name: "purple", color: "#6A1B9A" },
  { name: "pink", color: "#AD1457" },
  { name: "cyan", color: "#0097A7" },
  { name: "teal", color: "#00796B" },
  { name: "green", color: "#43A047" },
  { name: "lightgreen", color: "#689F38" },
  { name: "lime", color: "#AFB42B" },
  { name: "yellow", color: "#FBC02D" },
  { name: "amber", color: "#FFA000" },
  { name: "orange", color: "#FB8C00" },
  { name: "deeporange", color: "#D84315" },
  { name: "brown", color: "#5D4037" },
  { name: "grey", color: "#616161" },
  { name: "bluegrey", color: "#546E7A" },
  { name: "indigo", color: "#3F51B5" },
];

export const menus: MenuItem[] = [
  {
    label: "Tools",
    icon: "pi pi-fw pi-briefcase",
    items: [
      {
        label: "Working Hour",
        icon: "pi pi-fw pi-calendar",
        to: "/workinghour",
        items: [],
        permission: "view_working_hours",
      },
      {
        label: "Planning",
        icon: "pi pi-fw pi-calendar",
        to: "/planning",
        items: [],
        permission: "view_planning",
      },
      {
        label: "Rapport",
        icon: "pi pi-fw pi-calendar",
        to: "/report",
        items: [],
        permission: "view_report",
      },
    ],
  },
  {
    label: "Resources",
    icon: "",
    items: [
      {
        label: "Task",
        icon: "pi pi-fw pi-cog",
        to: "/tasks",
        items: [],
        permission: "view_tasks",
      },
      {
        label: "Project",
        icon: "pi pi-fw pi-cog",
        to: "/projects",
        items: [],
        permission: "view_projects",
      },
      {
        label: "Project Category",
        icon: "pi pi-fw pi-cog",
        to: "/project-categories",
        items: [],
        permission: "view_project_categories",
      },
      {
        label: "Monitoring",
        icon: "pi pi-fw pi-cog",
        to: "/todos",
        items: [
          {
            label: "Monitoring Daily",
            icon: "pi pi-fw pi-cog",
            to: "/todos?category=daily",
            items: [],
          },
          {
            label: "Monitoring History",
            icon: "pi pi-fw pi-cog",
            to: "/todos?category=history",
            items: [],
          },
        ],
        permission: "view_todos",
      },
      {
        label: "Monitoring Category",
        icon: "pi pi-fw pi-cog",
        to: "/todo-categories",
        items: [],
        permission: "view_todos",
      },
      {
        label: "Group",
        icon: "pi pi-fw pi-cog",
        to: "/groups",
        items: [],
        permission: "view_employees",
      },
      {
        label: "Employee",
        icon: "pi pi-fw pi-cog",
        to: "/employees",
        items: [],
        permission: "view_employees",
      },
      {
        label: "Permission",
        icon: "pi pi-fw pi-cog",
        to: "/permissions",
        items: [],
        permission: "view_permissions",
      },
      {
        label: "Role",
        icon: "pi pi-fw pi-cog",
        to: "/roles",
        items: [],
        permission: "view_roles",
      },
      {
        label: "User",
        icon: "pi pi-fw pi-cog",
        to: "/users",
        items: [],
        permission: "view_users",
      },
      {
        label: "Document",
        icon: "pi pi-fw pi-cog",
        to: "/documents",
        items: [],
        permission: "view_users",
      },
    ],
  },
];

export const constScales: number[] = [12, 13, 14, 15, 16];
