import { Translate } from "@/types/theme/translate";
import { App } from "vue";
import en from "./en.json";
import fr from "./fr.json";

export default {
  install: (app: App, themeStore: any) => {
    const language: { [index: string]: any } =
      themeStore.themeConfig.language == "fr" ? fr : en;

    const translateModule: Translate = (key) => {
      return language[key];
    };

    app.config.globalProperties.$translate = translateModule;

    app.provide("translate", app.config.globalProperties.$translate);
  },
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $translate: Translate;
  }
}
