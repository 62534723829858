import Permission from "@/types/models/permission";
import ApiService from "../api.service";

const PermissionService = {
  async create(resourceData: Permission) {
    ApiService.post("/api/permissions", resourceData);
  },
  async getData() {
    return ApiService.get("/api/permissions");
  },
};

export default PermissionService;
