import { ref } from "vue";

export default function usetToLocaleDateTimeString(value: string) {
  return ref(new Date(value)).value.toLocaleString("fr-CH", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}
