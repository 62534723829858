import { ThemeConfiguration } from "@/types/models/themeConfiguration";
import ApiService from "./api.service";

const ThemeService = {
  saveThemeConfiguration(themeConfig: ThemeConfiguration) {
    return ApiService.post("api/theme-configuration", {
      themeConfig,
    });
  },
  getUserThemeConfig() {
    return ApiService.get("api/theme-configuration/get-user-theme-config");
  },
};

export default ThemeService;
