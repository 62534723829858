import WorkingHour from "@/types/models/workingHour";
import ApiService from "../api.service";

const WorkingHourService = {
  async create(resourceData: WorkingHour) {
    return ApiService.post("api/working-hours", resourceData);
  },
  async getSingleWorkingHourData(id: number | string) {
    return ApiService.get("api/working-hours/" + id);
  },
  async delete(id: number) {
    return ApiService.delete("api/working-hours/" + id);
  },
  async resourceRelationship(id: number | string) {
    return ApiService.get("api/working-hour/relationships/" + id);
  },
  async getDayWorkingHours(id: number) {
    return ApiService.get("api/working-hour/getDayHours/" + id);
  },
  async getEmployeeData(employeeId: number, start: string, end: string) {
    return ApiService.get(
      "api/working-hour/get-employee-working-hours/" + employeeId,
      {
        start,
        end,
      }
    );
  },
};

export default WorkingHourService;
