import { intervalToDuration } from "date-fns";

export default function useDateDuration() {
  function durationToTime(durationMiliSec: number): string {
    const duration = intervalToDuration({ start: 0, end: durationMiliSec });
    const minutes =
      (duration.minutes as number) < 10
        ? "0" + (duration.minutes as number)
        : duration.minutes?.toString();
    const hours =
      (duration.hours as number) +
      (duration.days as number) * 24 +
      (duration.months as number) * 31 * 24;
    return `${hours}h${minutes}`;
  }

  return { durationToTime };
}
