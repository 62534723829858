import RoleService from "@/services/models/role.service";
import Role from "@/types/models/role";

import { defineStore } from "pinia";

export const useRoleEditStore = defineStore("RoleEditStore", {
  state() {
    return {
      currentRole: {} as Role,
    };
  },
  actions: {
    getSingleRoleData(id: number) {
      RoleService.getSingleData(id)
        .then((response) => {
          this.currentRole = response.data.role;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
});
