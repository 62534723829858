import frLocale from "@fullcalendar/core/locales/fr";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

export const calendarStaticOptions = {
  plugins: [
    resourceTimelinePlugin,
    dayGridPlugin,
    interactionPlugin,
    resourceTimeGridPlugin,
    listPlugin,
  ],
  schedulerLicenseKey: "0450070344-fcs-1617362093",
  eventTimeFormat: {
    hour: "numeric",
    minute: "2-digit",
    meridiem: false,
  },
  resourceAreaWidth: "16%",
  resourceGroupField: "groupName",
  refetchResourcesOnNavigate: true,
  firstDay: 1,
  allDaySlot: true,
  allDayContent: "To Do",
  nowIndicator: true,
  dayMaxEventRows: true,
  editable: true,
  slotEventOverlap: false,
  displayEventTime: true,
  slotMinTime: "07:00:00",
  slotMaxTime: "19:00:00",
  locales: [frLocale],
  height: 830,
  dragRevertDuration: 0,
  initialView: "resourceTimelineDay",
  headerToolbar: {
    center:
      "resourceTimelineHorizontal resourceTimelineVertical timeGridWeekCustom dayGridMonthCustom listToDo", // buttons for switching between views
    right: "today prevYear,prev,next,nextYear",
  },
  views: {
    resourceTimelineHorizontal: {
      type: "resourceTimeline",
      dayCount: 1,
      buttonText: "Day",
      titleFormat: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      },
    },
    resourceTimelineVertical: {
      type: "resourceTimeGridDay",
      buttonText: "Day Vertical",
    },
    timeGridWeekCustom: {
      type: "timeGrid",
      buttonText: "Week",
      duration: {
        day: 5,
      },
    },
    dayGridMonthCustom: {
      type: "dayGrid",
      buttonText: "Month",
      duration: {
        month: 1,
      },
    },
    listToDo: {
      type: "list",
      dayCount: 1,
      buttonText: "List",
    },
  },
  selectable: true,
  displayEventEnd: true,
};
