import Project from "@/types/models/project";
import ApiService from "../api.service";

const ProjectService = {
  async create(resourceData: Project) {
    return ApiService.post("api/projects", resourceData);
  },
  async getData() {
    return ApiService.get("/api/projects");
  },
  async getDropDown() {
    return ApiService.get("/api/dropdown/projects");
  },
};

export default ProjectService;
