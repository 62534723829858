import { ResourceName } from "@/types/models/resourceName";
import ApiService from "@/services/api.service";
import ProjectService from "@/services/models/project.service";
import EmployeeService from "@/services/models/employee.service";
import GroupService from "@/services/models/group.service";
import ProjectCategoryService from "@/services/models/project_category.service";
import TaskService from "@/services/models/task.service";

interface Field {
  fieldName: string;
  value: string | number | boolean | Date | any[];
}

export default function useEditField() {
  async function getDropdownOptions(
    fieldName: string,
    resourceName: ResourceName,
    dependsOnResourceName?: ResourceName,
    dependsOnResourceId?: number
  ) {
    let response;
    if (fieldName.search("_id") !== -1 || fieldName.search("_by") !== -1) {
      //relation field
      switch (fieldName.replace("_id", "")) {
        case "project":
          response = await ProjectService.getDropDown();
          break;
        case "project_category":
          response = await ProjectCategoryService.getDropDown(
            dependsOnResourceName,
            dependsOnResourceId
          );
          break;
        case "group":
          response = await GroupService.getDropDown();
          break;
        case "employee":
        case "done_by":
        case "requested_by":
          response = await EmployeeService.getDropDown();
          break;
        default:
          break;
      }
    } else {
      //resource field
      switch (resourceName) {
        case "Task":
          response = await TaskService.getEnum(fieldName);
          response.data = response.data.map((item: any) => {
            return { id: item, label: item };
          });
          break;
        case "WorkingHour":
          break;
        default:
          break;
      }
    }

    return response?.data;
  }

  async function editFieldValue(
    resourceName: ResourceName,
    resourceId: number,
    field: Field
  ) {
    // Check if field is an array, is true then it's a relation field
    if (Array.isArray(field.value)) {
      field.value = field.value.map((item) => item.id);
    }
    return ApiService.post(
      `/api/${resourceName.toLowerCase()}/${resourceId}/update-field`,
      field
    );
  }

  return {
    editFieldValue,
    getDropdownOptions,
  };
}
