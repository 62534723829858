import Group from "@/types/models/group";
import ApiService from "../api.service";

const GroupService = {
  async create(resourceData: Group) {
    ApiService.post("api/groups", resourceData);
  },
  async getData() {
    return ApiService.get("api/groups");
  },
  async getDropDown() {
    return ApiService.get("/api/dropdown/groups");
  },
  async getSingleData(id: number | string) {
    return ApiService.get("api/groups/" + id);
  },
  async delete(id: number) {
    return ApiService.delete("api/groups/" + id);
  },
  async resourceRelationship(id: number | string) {
    return ApiService.get("api/group/relationships/" + id);
  },
};

export default GroupService;
