import ApiService from "../api.service";
import TodoCategory from "@/types/models/todoCategory";

const TodoCategoryService = {
  async create(resourceData: TodoCategory) {
    return ApiService.post("api/todo-categories", resourceData);
  },
  async getData() {
    return ApiService.get("/api/todo-categories");
  },
  async getDropDown() {
    return ApiService.get("/api/dropdown/todoCategories");
  },
};

export default TodoCategoryService;
