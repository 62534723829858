import ApiService from "@/services/api.service";
import User from "@/types/models/user";

const UserService = {
  async create(resourceData: User) {
    return ApiService.post("api/users", resourceData);
  },
  async getData() {
    return ApiService.get("api/users");
  },
  async hasPermissionTo(permissionSlug: string, userId: number) {
    return ApiService.get("api/user/" + userId + "/has-permission-to");
  },
  async getSingleData(id: number | string) {
    return ApiService.get("api/users/" + id);
  },
  async delete(id: number) {
    return ApiService.delete("api/users/" + id);
  },
  async getAllPermission() {
    return ApiService.get("api/user/get-all-permissions");
  },
  async resourceRelationship(id: number | string) {
    return ApiService.get("api/user/relationships/" + id);
  },
};

export default UserService;
