import { reactive } from "vue";

export default function useFetchData() {
  const dropDowns = reactive([]);
  const enums = reactive([]);

  async function fetchData(
    service: any,
    data: any[],
    params?: { colName?: string }
  ) {
    try {
      let response;
      if (params && params.colName) {
        response = await service.getEnum(params.colName);
      } else {
        response = await service.getDropDown();
      }
      data.splice(0, data.length, ...response.data);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    dropDowns,
    enums,
    fetchData,
  };
}
