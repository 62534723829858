import { createApp, h } from "vue";
import AppWrapper from "./AppWrapper.vue";
import PrimeVue from "primevue/config";
import { createPinia } from "pinia";
import router from "./router";
import Tooltip from "primevue/tooltip";
import BadgeDirective from "primevue/badgedirective";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import TranslatePlugin from "./plugins/lang/translate-plugin";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
// import "./assets1/demo/flags/flags.css";

//Stores
import ConfigStore from "./stores/ConfigStore";
import { PRIMEVUE_CONFIG } from "./keys";
import { useThemeStore } from "./stores/ThemeStore";

const app = createApp({
  render() {
    return h(AppWrapper);
  },
});

app.use(PrimeVue, { ripple: true, inputStyle: "filled" });
app.use(createPinia());
app.use(router);

app.provide(PRIMEVUE_CONFIG, ConfigStore);
const themeStore = useThemeStore(createPinia());
app.use(TranslatePlugin, themeStore);
app.use(ConfirmationService);
app.use(ToastService);

app.directive("tooltip", Tooltip);
app.directive("badge", BadgeDirective);
app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);

// app.config.globalProperties.$appState = reactive({
//   RTL: false,
//   isNewThemeLoaded: false,
//   layoutMode: "light",
// });

app.mount("#app");
