import { createRouter, createWebHashHistory } from "vue-router";
import { useAuthStore } from "./stores/AuthStore";
import Planning from "./pages/Planning.vue";
import TaskPage from "./pages/Models/Task.vue";
import ProjectPage from "./pages/Models/Project.vue";
import ProjectCategoryPage from "./pages/Models/ProjectCategory.vue";
import TodoCategoryPage from "./pages/Models/TodoCategory.vue";
import TodoPage from "./pages/Models/Todo.vue";
import EmployeePage from "./pages/Models/Employee.vue";
import UserPage from "./pages/Models/User.vue";
import PermissionPage from "./pages/Models/Permission.vue";
import GenericForm from "./pages/Form.vue";
import GenericDetailsPage from "./pages/GenericDetails.vue";
import RolePage from "./pages/Models/Role.vue";
import RoleEditForm from "./components/Models/Role/EditForm.vue";
import DocumentPage from "./pages/Models/Document.vue";
import GroupPage from "./pages/Models/Group.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    exact: true,
    component: Planning,
    meta: {
      breadcrumb: [{ label: "Planning" }],
      authRequired: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/planning",
    name: "Planning",
    component: () => import("./pages/Planning.vue"),
    meta: {
      breadcrumb: [{ label: "Planning" }],
      authRequired: true,
    },
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("./pages/Report.vue"),
    meta: {
      breadcrumb: [{ label: "Report" }],
      authRequired: true,
    },
  },
  {
    path: "/tasks",
    name: "tasks",
    exact: true,
    component: TaskPage,
    meta: {
      breadcrumb: [{ label: "Tasks" }],
      authRequired: true,
    },
  },
  {
    path: "/projects",
    name: "projects",
    exact: true,
    component: ProjectPage,
    meta: {
      breadcrumb: [{ label: "Project" }],
      authRequired: true,
    },
  },
  {
    path: "/project-categories",
    name: "projectCategories",
    exact: true,
    component: ProjectCategoryPage,
    meta: {
      breadcrumb: [{ label: "Project Category" }],
      authRequired: true,
    },
  },
  {
    path: "/todos",
    name: "todos",
    component: TodoPage,
    meta: {
      breadcrumb: [{ label: "Todos" }],
      authRequired: true,
    },
  },
  {
    path: "/todo-categories",
    name: "todoCategories",
    exact: true,
    component: TodoCategoryPage,
    meta: {
      breadcrumb: [{ label: "Todo Category" }],
      authRequired: true,
    },
  },
  {
    path: "/employees",
    name: "employees",
    exact: true,
    component: EmployeePage,
    meta: {
      breadcrumb: [{ label: "Employees" }],
      authRequired: true,
    },
  },
  {
    path: "/groups",
    name: "groups",
    exact: true,
    component: GroupPage,
    meta: {
      breadcrumb: [{ label: "Group" }],
      authRequired: true,
    },
  },
  {
    path: "/users",
    name: "users",
    exact: true,
    component: UserPage,
    meta: {
      breadcrumb: [{ label: "Users" }],
      authRequired: true,
    },
  },
  {
    path: "/documents",
    name: "documents",
    exact: true,
    component: DocumentPage,
    meta: {
      breadcrumb: [{ label: "Users" }],
      authRequired: true,
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    exact: true,
    component: PermissionPage,
    meta: {
      breadcrumb: [{ label: "Permissions" }],
      authRequired: true,
    },
  },
  {
    path: "/create/:resourceName",
    name: "ResourceCreate",
    props: true,
    component: GenericForm,
    meta: {
      breadcrumb: [{ label: "Form" }],
      authRequired: true,
    },
  },
  {
    path: "/details/:resourceName/:resourceId",
    name: "ResourceDetails",
    props: true,
    component: GenericDetailsPage,
    meta: {
      breadcrumb: [{ label: "Details" }],
      authRequired: true,
    },
  },
  {
    path: "/workinghour",
    name: "WorkingHour",
    component: () => import("./pages/WorkingHour.vue"),
    meta: {
      breadcrumb: [{ label: "WorkingHour" }],
      authRequired: true,
    },
  },
  {
    path: "/roles",
    name: "roles",
    exact: true,
    component: RolePage,
    meta: {
      breadcrumb: [{ label: "Roles" }],
      authRequired: true,
    },
  },
  {
    path: "/edit/role/:roleId",
    name: "RoleEdit",
    props: true,
    component: RoleEditForm,
    meta: {
      breadcrumb: [{ label: "Edit" }],
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const authStore = useAuthStore();
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  // If auth is required and the user is logged in...
  if (authStore.loggedIn) {
    // Validate the local user token...
    return authStore.validate().then((validUser) => {
      // Then continue if the token still represents a valid user,
      // otherwise redirect to login.
      validUser ? next() : redirectToLogin();
    });
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  }
});

export default router;
